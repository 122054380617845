.project-preview-background {
	position: static !important;
	top: 15vh;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 100vh;
	width: 100%;
	padding: var(--huge);
}

.background-scroll-image {
	position: fixed;
	left: 0;
	display: flex;
	background-color: var(--background);
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: 100vw;
	height: 100vh;
	max-height: 0px;
	transition: all 0s linear;
}

.preview-details-background {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	text-align: left;
	width: 50%;
	gap: var(--small);
	padding: 0px 0px var(--small) var(--large) !important;
	z-index: 1;
}

/* large screens */
@media screen and (max-width: 1200px) {
	.project-preview-background {
		padding: var(--large);
	}
}

/* small screens */
@media screen and (max-width: 1024px) {
	.project-preview-background {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		top: 10vh;
		height: 70vh;
		width: 100%;
		padding: var(--large);
		margin-bottom: var(--huge);
		background-attachment: scroll;
		background-position: 0% 0%;
		background-repeat: no-repeat;
		background-size: 200%;
		margin-bottom: 0;
	}

	.preview-details-background {
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 100%;
		padding: var(--small) !important;
	}
}

/* tablets */
@media screen and (max-width: 768px) {
	.project-preview-background {
		height: 80vh;
		top: 20vh;
		padding: var(--medium);
	}
}

/* phones */
@media screen and (max-width: 480px) {
	.project-preview-background {
		top: 15vh;
		height: 80vh;
		width: 100%;
		margin-bottom: var(--large);
	}

	.preview-details-background {
		padding: 0px !important;
	}
}
