/* global styles */
body {
	font-family: 'Avenir Next', Avenir, Arial, Helvetica, sans-serif;
	line-height: 1.25;
	font-weight: 400;
	font-size: 14px;
}

/* heading sizes */
h1 {
	font-size: 2.4em;
	font-weight: 600;
	transform: translateX(-2px);
}

h2 {
	font-size: 1.5em;
	font-weight: 600;
}

h3 {
	font-size: 1.2em;
	font-weight: 400;
}

h4 {
	font-size: 0.8em;
	font-weight: 400;
}

p,
a {
	font-size: 1em;
	font-weight: 400;
}
