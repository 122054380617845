* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
	scroll-behavior: smooth;
	background: var(--background);
}

body {
	min-height: 100%;
	width: 100%;
}

input,
select,
option,
optgroup,
textarea,
button,
pre,
code {
	font-size: 100%;
	font-family: inherit;
}

ol,
ul {
	list-style: none;
}

a,
a:visited {
	color: inherit;
	text-decoration: none;
}
