footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: var(--large) 0px;
    margin: 0px auto;
}

.footer-detail {
    opacity: 0.5;
    font-size: 1.2em;
}

.footer-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-contact li:last-of-type {
    margin-left: var(--medium);
}

.focused {
    transition: all 1s ease;
    transform: translateY(-25vh);
    flex-direction: column;
}

.focused li {
    margin: var(--small);
}

/* small screens */
@media screen and (max-width: 1024px) {}

/* tablets */
@media screen and (max-width: 768px) {

    footer {
        height: 100vh;
    }

    .footer-contact {
        flex-direction: column;
    }

    .footer-detail {
        display: none;
    }

    .focused {
        transform: translateY(-15vh);
    }

}

/* phones */
@media screen and (max-width: 480px) {}