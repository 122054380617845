header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: var(--large);
	margin: 0px;
	z-index: 99;
}

.site-title {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.site-title a {
	opacity: 1;
}

.site-title p {
	user-select: none;
}

/* tablets */
@media screen and (max-width: 768px) {
	header {
		position: fixed;
		background: var(--background75);
		-webkit-backdrop-filter: blur(15px);
		backdrop-filter: blur(15px);
		box-shadow: 0px 0px 30px var(--background75);
		padding: var(--medium);
		height: fit-content;
	}
}
