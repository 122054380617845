* {
	transition: all 0.5s ease;
}

/* fade */
@keyframes fade-in {
	from {
		display: flex;
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		display: none;
	}
}

/* slide */
@keyframes slide-up {
	from {
		display: flex;
		transform: translateY(50%);
		opacity: 0;
	}

	to {
		transform: translateY(0%);
		opacity: 1;
	}
}

@keyframes slide-up-bold {
	from {
		display: flex;
		transform: translateY(50%);
	}

	to {
		transform: translateY(0%);
	}
}

@keyframes slide-down {
	from {
		transform: translateY(0%);
		opacity: 1;
	}

	to {
		transform: translateY(50%);
		opacity: 0;
	}
}

/* scale */
@keyframes scale-in {
	from {
		display: block;
		transform: scale(0.8);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes scale-out {
	from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(0.8);
		opacity: 0;
		display: none;
	}
}

@keyframes shrink {
	from {
		width: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(0.5);
		opacity: 0;
	}
}

@keyframes grow {
	from {
		width: scale(0.5);
		opacity: 1;
	}

	to {
		transform: scale(1);
		opacity: 0;
	}
}

/* hero */
@keyframes hero-buffer {
	from {
		margin-bottom: 30vh;
	}

	to {
		margin-bottom: 0vh;
	}
}

/* scroll indicator */
@keyframes droplet {
	from {
		transform: matrix(1, 0, 0, 1, 0, -100);
	}

	10% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		border-radius: 100%;
	}

	20% {
		transform: matrix(2, 0, 0, 0.1, 0, 25);
		border-radius: 20%;
	}

	40% {
		transform: matrix(0.8, 0, 0, 1.2, 0, -50);
		border-radius: 100%;
	}

	55% {
		transform: matrix(1.2, 0, 0, 0.8, 0, 10);
	}

	70% {
		transform: matrix(0.9, 0, 0, 1.1, 0, -10);
	}

	85% {
		transform: matrix(1.05, 0, 0, 0.95, 0, 2);
	}

	to {
		transform: matrix(1, 0, 0, 1, 0, 0);
	}
}
