#cascading-solar-system {
	position: relative;
	margin-bottom: var(--huge);
}

.css-container {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	background-color: var(--background);
	background-image: radial-gradient(circle at center, white 0%, transparent 1%), radial-gradient(circle at center, white 0%, transparent 1.5%), radial-gradient(circle at center, white 0%, transparent 2%), radial-gradient(circle at center, white 0%, transparent 1.5%), radial-gradient(circle at center, white 0%, transparent 1%), radial-gradient(circle at center, white 0%, transparent 1.5%), radial-gradient(circle at center, white 0%, transparent 2%), radial-gradient(circle at center, white 0%, transparent 1.5%), radial-gradient(circle at center, white 0%, transparent 1%), radial-gradient(circle at center, white 0%, transparent 1%);
	background-size: 100px 100px;
	background-position: -50px 0px, 10px 20px, -26px -45px, 12px -12px, -12px 40px, 30px 48px, 30px -20px, -20px -25px, -20px 10px, 55px 20px;
	max-width: 100vw;
	overflow-x: hidden;
}

.css-container::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(var(--background), transparent, var(--background)), linear-gradient(to right, var(--background), var(--background25), var(--background)), linear-gradient(5deg, rgba(5, 5, 5, 0.4) 30%, rgba(255, 0, 0, 0.1), rgba(5, 5, 5, 0.4) 60%), linear-gradient(-15deg, rgba(5, 5, 5, 0.4) 30%, rgba(0, 255, 0, 0.1), rgba(5, 5, 5, 0.4) 70%), linear-gradient(rgba(5, 5, 5, 0.2), rgba(0, 0, 255, 0.15), rgba(5, 5, 5, 0.2));
}

.planet {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	/* box-shadow: 20px 20px 200px 0 black; */
	width: 500px;
	height: 500px;
	transition: all ease-in-out 50ms;
}

/* general planet rules */
.hazey-border,
.hazey-border-inset {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	width: 100%;
	height: 100%;
	/* backdrop-filter: blur(10px); */
}

.shadow {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	width: 101%;
	height: 101%;
	background: radial-gradient(circle at 15% 20%, hsla(0, 0%, 0%, 0), 40%, hsl(0, 0%, 0%) 80%);
	/* backdrop-filter: blur(10px); */
}

/* surfaces */
.surface {
	position: absolute;
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	width: 99%;
	height: 99%;
	opacity: 0.5;
}

.surface-color-gray {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(hsla(0, 0%, 0%, 0.3) 1%, transparent 40%), radial-gradient(hsla(0, 0%, 0%, 0.3) 1%, transparent 20%);
	background-size: 60% 40%;
}

.surface-color-green {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(darkgreen 1%, transparent 60%), radial-gradient(darkgreen 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-white {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(rgba(255, 255, 255, 0.5) 1%, transparent 60%), radial-gradient(rgba(255, 255, 255, 0.5) 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-red {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(red 1%, transparent 60%), radial-gradient(red 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-gold {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(goldenrod 1%, transparent 60%), radial-gradient(gold 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-yellow {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(yellow 1%, transparent 60%), radial-gradient(yellow 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-orange {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(orange 1%, transparent 60%), radial-gradient(orange 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-sand {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(chocolate 1%, transparent 60%), radial-gradient(chocolate 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-pink {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(pink 1%, transparent 60%), radial-gradient(salmon 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-blue {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(dodgerblue 1%, transparent 60%), radial-gradient(dodgerblue 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-sky {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(skyblue 1%, transparent 60%), radial-gradient(skyblue 1%, transparent 30%);
	background-size: 60% 40%;
}

.surface-color-navy {
	position: absolute;
	display: flex;
	width: 150%;
	height: 150%;
	background-image: radial-gradient(darkblue 1%, transparent 60%), radial-gradient(darkblue 1%, transparent 30%);
	background-size: 60% 40%;
}

.saturn-border {
	box-shadow: -12px -12px 24px -8px hsla(210, 100%, 95%, 0.75);
}

.saturn-border-inset {
	box-shadow: inset 4px 4px 24px hsla(210, 100%, 95%, 0.95);
}

/* surface */
#saturn-surface {
	animation: rotate-saturn-surface 60s ease-in-out infinite both;
	opacity: 1;
}

#saturn-surface-color-1 {
	position: absolute;
	display: flex;
	width: 110%;
	height: 110%;
	background-image: linear-gradient(to top, saddlebrown, tan, wheat, tan, wheat, burlywood, moccasin, linen, moccasin, sienna, wheat, linen, wheat, tan, saddlebrown);
	animation: move-saturn-surface 60s ease-in-out infinite both;
}

/* rings */
#saturn-rings-back-rotator,
#saturn-rings-front-rotator {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: rotate-saturn-rings 60s ease-in-out infinite both;
}

#saturn-rings-back,
#saturn-rings-front {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transform: rotate(-25deg);
	animation: resize-saturn-rings 60s 15s ease-in-out infinite both;
	opacity: 0.75;
}

#saturn-rings-back-rotator,
#saturn-rings-back {
	z-index: -1;
}

/* ring color */
:root {
	--saturn-ring-color: transparent 30%, linen, wheat, antiquewhite, linen, antiquewhite, snow, linen, white, transparent, white, antiquewhite, linen, wheat, oldlace, transparent 70%;
}

#saturn-rings-front-top,
#saturn-rings-front-bottom,
#saturn-rings-back-top,
#saturn-rings-back-bottom {
	background-position: 85% 0%, 0 0;
	background-size: 35% 100%, 100% 100%;
	background-repeat: no-repeat;
	width: 1500px;
	height: 75px;
}

#saturn-rings-back-top {
	background-image: radial-gradient(ellipse at 50% 100%, rgba(0, 0, 0, 0.75), transparent 50%), radial-gradient(ellipse at bottom, var(--saturn-ring-color));
	border-radius: 100% 100% 0 0;
}

#saturn-rings-front-bottom {
	background-image: radial-gradient(ellipse at 50% 0%, rgba(0, 0, 0, 0.75), transparent 50%), radial-gradient(ellipse at top, var(--saturn-ring-color));
	border-radius: 0 0 500px 500px;
}

#saturn-rings-front-top,
#saturn-rings-back-bottom {
	opacity: 0;
}

/* moons */
.saturn-moon-border-inset {
	box-shadow: inset 1px 1px 6px hsla(25, 100%, 90%, 0.95);
}

/* moon 1 */
#saturn-moon-1 {
	position: absolute;
	background: radial-gradient(circle at 15% 20%, hsl(0, 0%, 100%), hsl(0, 0%, 10%));
	border-radius: 100%;
	width: 100px;
	height: 100px;
	animation: saturn-moon-1-resize 90s 22.5s ease-in-out infinite both;
}

#saturn-moon-1-orbit {
	animation: moon-orbit-xx-large 90s ease-in-out infinite both;
}

/* moon surface */
#saturn-moon-1-surface-color-1 {
	animation: spin-surface 5s linear infinite both;
}

#saturn-moon-1-surface-color-2 {
	animation: spin-surface 6s linear infinite both;
	transform: translateY(5%);
}

/* moon 2 */
#saturn-moon-2 {
	position: absolute;
	background: radial-gradient(circle at 15% 20%, hsl(55, 100%, 90%), hsl(55, 0%, 10%));
	border-radius: 100%;
	width: 100px;
	height: 100px;
	animation: saturn-moon-2-resize 320s 80s ease-in-out infinite both;
}

#saturn-moon-2-orbit {
	animation: moon-orbit-x-large 320s ease-in-out infinite both;
}

/* moon surface */
#saturn-moon-2-surface-color-1 {
	animation: spin-surface 5s linear infinite both;
}

#saturn-moon-2-surface-color-2 {
	animation: spin-surface 6s linear infinite both;
	transform: translateY(5%);
}

/* moon 3 */
#saturn-moon-3 {
	position: absolute;
	background: radial-gradient(circle at 15% 20%, hsl(0, 100%, 90%), hsl(0, 0%, 10%));
	border-radius: 100%;
	width: 100px;
	height: 100px;
	animation: saturn-moon-3-resize 1586s 396.5s ease-in-out infinite both;
}

#saturn-moon-3-orbit {
	animation: moon-orbit-large 1586s ease-in-out infinite both;
}

/* moon surface */
#saturn-moon-3-surface-color-1 {
	animation: spin-surface 5s linear infinite both;
}

#saturn-moon-3-surface-color-2 {
	animation: spin-surface 6s linear infinite both;
	transform: translateY(5%);
}

/* moon 4 */
#saturn-moon-4 {
	position: absolute;
	background: radial-gradient(circle at 15% 20%, hsl(200, 100%, 90%), hsl(200, 100%, 10%));
	border-radius: 100%;
	width: 100px;
	height: 100px;
	animation: saturn-moon-4-resize 38s 9.5s ease-in-out infinite both;
}

#saturn-moon-4-orbit {
	animation: moon-orbit-medium 38s ease-in-out infinite both;
}

/* moon surface */
#saturn-moon-4-surface-color-1 {
	animation: spin-surface 5s linear infinite both;
}

#saturn-moon-4-surface-color-2 {
	animation: spin-surface 6s linear infinite both;
	transform: translateY(5%);
}

/* moon 5 */
#saturn-moon-5 {
	position: absolute;
	background: radial-gradient(circle at 15% 20%, hsl(30, 100%, 90%), hsl(30, 0%, 10%));
	border-radius: 100%;
	width: 100px;
	height: 100px;
	animation: saturn-moon-5-resize 54.8s 13.7s ease-in-out infinite both;
}

#saturn-moon-5-orbit {
	animation: moon-orbit-small 54.8s ease-in-out infinite both;
}

/* moon surface */
#saturn-moon-5-surface-color-1 {
	animation: spin-surface 5s linear infinite both;
}

#saturn-moon-5-surface-color-2 {
	animation: spin-surface 6s linear infinite both;
	transform: translateY(5%);
}

/* animations */
/* surface animations */
@keyframes spin-surface {
	0% {
		background-position: 0% 0%, 75% 35%;
	}

	to {
		background-position: 150% 0%, 225% 35%;
	}
}

@keyframes spin-surface-reverse {
	0% {
		background-position: 150% 0%, 225% 35%;
	}

	to {
		background-position: 0% 0%, 75% 35%;
	}
}

@keyframes spin-surface-down {
	0% {
		background-position: 0% 0%, 75% 35%;
	}

	to {
		background-position: 0% 133%, 75% 168%;
	}
}

@keyframes spin-surface-up {
	0% {
		background-position: 0% 133%, 75% 168%;
	}

	to {
		background-position: 0% 0%, 75% 35%;
	}
}

@keyframes spin-surface-down-right {
	0% {
		background-position: 0% 0%, 75% 35%;
	}

	to {
		background-position: 150% 133%, 225% 168%;
	}
}

@keyframes spin-surface-up-right {
	0% {
		background-position: 0% 133%, 75% 168%;
	}

	to {
		background-position: 150% 0%, 225% 35%;
	}
}

/* moon orbit paths */
@keyframes moon-orbit-xx-large {
	from {
		transform: matrix(1, 0, 0, 1, 1000, -350);
		z-index: -16;
	}

	50% {
		transform: matrix(1, 0, 0, 1, -1000, 350);
		z-index: -16;
	}

	51% {
		transform: matrix(1, 0, 0, 1, -1000, 350);
		z-index: 16;
	}

	to {
		transform: matrix(1, 0, 0, 1, 1000, -350);
		z-index: 16;
	}
}

@keyframes moon-orbit-x-large {
	from {
		transform: matrix(1, 0, 0, 1, -950, 300);
		z-index: 15;
	}

	50% {
		transform: matrix(1, 0, 0, 1, 950, -300);
		z-index: 15;
	}

	51% {
		transform: matrix(1, 0, 0, 1, 950, -300);
		z-index: -15;
	}

	to {
		transform: matrix(1, 0, 0, 1, -950, 300);
		z-index: -15;
	}
}

@keyframes moon-orbit-large {
	from {
		transform: matrix(1, 0, 0, 1, 900, -250);
		z-index: -14;
	}

	50% {
		transform: matrix(1, 0, 0, 1, -900, 250);
		z-index: -14;
	}

	51% {
		transform: matrix(1, 0, 0, 1, -900, 250);
		z-index: 14;
	}

	to {
		transform: matrix(1, 0, 0, 1, 900, -250);
		z-index: 14;
	}
}

@keyframes moon-orbit-medium {
	from {
		transform: matrix(1, 0, 0, 1, -850, 200);
		z-index: 13;
	}

	50% {
		transform: matrix(1, 0, 0, 1, 850, -200);
		z-index: 13;
	}

	51% {
		transform: matrix(1, 0, 0, 1, 850, -200);
		z-index: -13;
	}

	to {
		transform: matrix(1, 0, 0, 1, -850, 200);
		z-index: -13;
	}
}

@keyframes moon-orbit-small {
	from {
		transform: matrix(1, 0, 0, 1, 800, -150);
		z-index: -12;
	}

	50% {
		transform: matrix(1, 0, 0, 1, -800, 150);
		z-index: -12;
	}

	51% {
		transform: matrix(1, 0, 0, 1, -800, 150);
		z-index: 12;
	}

	to {
		transform: matrix(1, 0, 0, 1, 800, -150);
		z-index: 12;
	}
}

@keyframes moon-orbit-x-small {
	from {
		transform: matrix(1, 0, 0, 1, 700, -150);
		z-index: -11;
	}

	50% {
		transform: matrix(1, 0, 0, 1, -700, 150);
		z-index: -11;
	}

	51% {
		transform: matrix(1, 0, 0, 1, -700, 150);
		z-index: 11;
	}

	to {
		transform: matrix(1, 0, 0, 1, 700, -150);
		z-index: 11;
	}
}

@keyframes moon-orbit-xx-small {
	from {
		transform: matrix(1, 0, 0, 1, 600, -150);
		z-index: -10;
	}

	50% {
		transform: matrix(1, 0, 0, 1, -600, 150);
		z-index: -10;
	}

	51% {
		transform: matrix(1, 0, 0, 1, -600, 150);
		z-index: 10;
	}

	to {
		transform: matrix(1, 0, 0, 1, 600, -150);
		z-index: 10;
	}
}

@keyframes rotate-saturn-surface {
	from {
		transform: rotate(-15deg);
	}

	50% {
		transform: rotate(15deg);
	}

	to {
		transform: rotate(-15deg);
	}
}

@keyframes move-saturn-surface {
	from {
		background-position: 0px 0px;
	}

	25% {
		background-position: 0px 20px;
	}

	50% {
		background-position: 0px 0px;
	}

	75% {
		background-position: 0px -20px;
	}

	to {
		background-position: 0px 0px;
	}
}

@keyframes rotate-saturn-rings {
	from {
		transform: rotate(-15deg);
		opacity: 1;
	}

	49% {
		transform: rotate(15deg);
		opacity: 1;
	}

	50% {
		transform: rotate(15deg);
		opacity: 0.8;
	}

	to {
		transform: rotate(-15deg);
		opacity: 0.8;
	}
}

@keyframes resize-saturn-rings {
	from {
		transform: scaleY(1.2);
	}

	50% {
		transform: scaleY(-1.2);
	}

	to {
		transform: scaleY(1.2);
	}
}

/* resize in orbit for perspective */
@keyframes saturn-moon-1-resize {
	0% {
		transform: matrix(0.13, 0, 0, 0.13, 0, 0);
	}

	50% {
		transform: matrix(0.8, 0, 0, 0.8, 0, 0);
	}

	100% {
		transform: matrix(0.13, 0, 0, 0.13, 0, 0);
	}
}

@keyframes saturn-moon-2-resize {
	0% {
		transform: matrix(1, 0, 0, 1, 0, 0);
	}

	50% {
		transform: matrix(0.35, 0, 0, 0.35, 0, 0);
	}

	100% {
		transform: matrix(1, 0, 0, 1, 0, 0);
	}
}

@keyframes saturn-moon-3-resize {
	0% {
		transform: matrix(0.11, 0, 0, 0.11, 0, 0);
	}

	50% {
		transform: matrix(0.6, 0, 0, 0.6, 0, 0);
	}

	100% {
		transform: matrix(0.11, 0, 0, 0.11, 0, 0);
	}
}

@keyframes saturn-moon-4-resize {
	0% {
		transform: matrix(0.4, 0, 0, 0.4, 0, 0);
	}

	50% {
		transform: matrix(0.13, 0, 0, 0.13, 0, 0);
	}

	100% {
		transform: matrix(0.4, 0, 0, 0.4, 0, 0);
	}
}

@keyframes saturn-moon-5-resize {
	0% {
		transform: matrix(0.11, 0, 0, 0.11, 0, 0);
	}

	50% {
		transform: matrix(0.5, 0, 0, 0.5, 0, 0);
	}

	100% {
		transform: matrix(0.11, 0, 0, 0.11, 0, 0);
	}
}

/* panel */
.css-panel-details {
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 50%;
	bottom: var(--large);
	color: var(--highlight);
	text-shadow: 5px 5px 5px var(--background-shadow-30);
	margin-right: var(--large);
}

.css-panel-details-outer {
	background-color: var(--background-tertiary-60);
	border: 2px solid var(--primary);
	border-radius: 3px;
	border-top-left-radius: 25px;
	border-bottom-right-radius: 25px;
	box-shadow: inset 0 0 var(--8) var(--primary);
}

.css-panel-details-inner {
	margin: var(--2);
	border: 2px solid var(--background-primary-30);
	border-radius: 5px;
	border-top-left-radius: 25px;
	border-bottom-right-radius: 25px;
	padding: var(--medium);
	margin: var(--small);
}

.preview-details-css {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	text-align: left;
	width: 100%;
	gap: var(--small);
	margin-bottom: var(--medium);
}

.icon:hover + .css-icon-description-right {
	opacity: 1;
}

.icon:hover + .css-icon-description-left {
	opacity: 1;
}

.css-icon-description-right {
	position: absolute;
	white-space: nowrap;
	transform: translateY(38px);
	font-weight: 500;
	cursor: default;
	opacity: 0;
	left: 0;
}

/* large screens */
@media screen and (max-width: 1024px) {
	.css-panel-details {
		left: 0vw;
		margin: 0 var(--large);
	}

	.preview-details-css {
		align-items: center;
		text-align: center;
	}
}

/* tablets */
@media screen and (max-width: 768px) {
	.css-panel-details {
		margin: 0 var(--medium);
	}

	.preview-details-css {
		margin-bottom: 0;
	}
}

/* phones */
@media screen and (max-width: 480px) {
	.css-panel-details {
		margin: 0 var(--small);
	}
}
