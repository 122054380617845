.about {
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: 1500px;
	padding: var(--medium);
}

.portrait {
	object-fit: cover;
	object-position: top;
	width: 50%;
	padding-right: var(--medium);
}

.about-bio {
	display: flex;
	flex-direction: column;
	width: 50%;
	padding-left: var(--medium);
	margin-top: var(--small);
	gap: var(--medium);
}

.skills {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--large);
	width: 100%;
	padding: var(--large);
}

.skills-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--large);
}

.skills-section h2 {
	text-align: center;
	border-bottom: 1px solid var(--light);
	width: 240px;
	padding: var(--small);
}

.skills-sub-section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--large);
	width: 100%;
}

.skills-icon-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--small);
	text-align: center;
	width: 70px;
}

.skills-icon {
	width: 45px;
}

/* large screens */
@media screen and (max-width: 1500px) {
	.about-bio h2:first-of-type {
		margin-bottom: 0px;
	}
}

/* medium screens */
@media screen and (max-width: 1200px) {
	.about {
		flex-direction: column;
		align-items: center;
		padding: var(--medium);
	}

	.portrait {
		height: 45vh;
		width: 100%;
		object-position: 0vh -20vh;
		padding-right: 0px;
	}

	.about-bio {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-left: 0px;
		margin-top: var(--large);
	}

	.button-block {
		justify-content: center;
		flex-wrap: wrap;
	}
}

/* small screens */
@media screen and (max-width: 1024px) {
	.portrait {
		object-position: 0vh -15vh;
	}
}

/* tablets */
@media screen and (max-width: 768px) {
	.portrait {
		object-position: top;
	}
}

/* phones */
@media screen and (max-width: 480px) {
	.about {
		padding: var(--small);
	}

	.portrait {
		height: 35vh;
	}
}
