.intro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 40vh;
	gap: var(--medium);
	animation: fade-in 1.5s ease-in-out 0.75s both;
}

.project-preview-left,
.project-preview-right {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: sticky;
	top: 15vh;
	height: 80vh;
	width: 100%;
	padding: var(--huge);
	z-index: 1;
}

.project-preview-right {
	flex-direction: row-reverse;
}

.preview-thumbnail-left,
.preview-thumbnail-right,
.preview-thumbnail-center {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	height: 50vh;
	width: 50%;
	z-index: 1;
}

.preview-thumbnail-right {
	justify-content: flex-start;
}

.preview-thumbnail-hero .project-thumbnail-desktop-link {
	width: 90%;
	opacity: 1;
	margin: var(--medium) auto auto 0px;
	transition: all ease-in-out 50ms !important;
}

.preview-thumbnail-left .project-thumbnail-desktop-link {
	width: 90%;
	opacity: 1;
	margin: var(--medium) auto auto 0px;
	transition: all ease-in-out 50ms !important;
}

.preview-thumbnail-right .project-thumbnail-desktop-link {
	width: 90%;
	opacity: 1;
	margin: var(--medium) 0px auto auto;
	transition: all ease-in-out 50ms !important;
}

.preview-thumbnail-center .project-thumbnail-desktop-link {
	width: 100%;
	opacity: 1;
	margin: var(--medium) auto auto auto;
	transition: all ease-in-out 50ms !important;
}

.project-thumbnail-desktop {
	position: absolute;
	object-fit: contain;
	width: 100%;
	animation: slide-up-bold 1.8s ease 1s both;
}

.thumbnail-animation-desktop {
	animation: fade-in 1s both 1.5s;
	padding: 3.7% 3.3%;
}

.project-thumbnail-phone-link {
	position: absolute;
	width: 10%;
	margin-top: 2%;
	opacity: 1;
	z-index: 1;
	transition: all 0.1s;
}

.project-thumbnail-phone {
	object-fit: contain;
	width: 100%;
	animation: slide-up-bold 2s ease 1s both;
}

.preview-details-left,
.preview-details-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	text-align: right;
	width: 50%;
	gap: var(--small);
	padding: var(--small) var(--large) var(--small) 0px;
	z-index: 1;
}

.preview-details-right {
	align-items: flex-start;
	text-align: left;
	padding: var(--small) 0px var(--small) var(--large) !important;
}

.preview-links {
	display: flex;
	user-select: none;
	gap: var(--small);
}

.preview-link-divider {
	font-weight: 300;
	opacity: 0.5;
	margin: 0px var(--small);
}

.project-description-left,
.project-description-right {
	margin-bottom: var(--small);
}

.project-description-left {
	text-align: right;
}

.project-description-right {
	text-align: left;
}

.show-description {
	animation: slide-up 1s both;
}

.hide-description {
	animation: slide-down 1s both;
}

.background-wrapper {
	width: 100%;
	margin: 60vh 0 85vh 0;
}

.scroll-indicator {
	position: absolute;
	transform: translateY(200px);
}

/* large screens */
@media screen and (max-width: 1200px) {
	.intro {
		height: 50vh;
	}

	.project-preview-left,
	.project-preview-right,
	.project-preview-hero {
		padding: var(--large);
	}
}

/* small screens */
@media screen and (max-width: 1024px) {
	.intro {
		height: 60vh;
	}

	.project-preview-left,
	.project-preview-right,
	.project-preview-hero {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		top: 10vh;
		height: 80vh;
		width: 100%;
		padding: var(--large);
		margin-bottom: var(--huge);
	}

	.preview-thumbnail-left,
	.preview-thumbnail-right,
	.preview-thumbnail-center {
		align-items: flex-start;
		height: 50vw;
		width: 75%;
	}

	.project-thumbnail-phone-link {
		width: 15%;
		margin-top: 3%;
	}

	.preview-details-left,
	.preview-details-right {
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 100%;
		padding: var(--small) !important;
	}

	.project-description-left,
	.project-description-right {
		text-align: center;
	}

	.background-wrapper {
		width: 100%;
		margin: var(--huge);
	}
}

/* tablets */
@media screen and (max-width: 768px) {
	.intro {
		height: 65vh;
		padding: var(--medium);
	}

	.intro h2:first-of-type {
		margin-bottom: 0px;
	}

	.project-preview-left,
	.project-preview-right,
	.project-preview-hero {
		height: 60vh;
		top: 20vh;
		padding: var(--medium);
	}

	#hero-container {
		transform: translateY(var(--medium));
	}

	.preview-thumbnail-left,
	.preview-thumbnail-right,
	.preview-thumbnail-center {
		height: 50vw;
	}

	.project-thumbnail-phone-link {
		width: 15%;
		margin-top: 5%;
	}
}

/* phones */
@media screen and (max-width: 480px) {
	.intro {
		height: 55vh;
	}

	.project-preview-left,
	.project-preview-right,
	.project-preview-hero {
		top: 15vh;
		height: 60vh;
		width: 100%;
		margin-bottom: var(--large);
	}

	.preview-thumbnail-left,
	.preview-thumbnail-right,
	.preview-thumbnail-center,
	#hero-container {
		height: 70vw;
		width: 100%;
	}

	.project-thumbnail-phone-link {
		width: 20%;
		margin-top: 10% !important;
	}

	.preview-details-left,
	.preview-details-right {
		padding: 0px !important;
	}
}

/* phones */
@media screen and (max-width: 320px) {
	.intro {
		height: 45vh;
	}
}
